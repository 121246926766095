import React,{Fragment} from 'react';
import NewHeader from '../components/newHeader';

function Contact(props) {
    return (
        <Fragment>
        <NewHeader />
        <div className="rs-breadcrumbs img3">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                        </h1>                       
                    </div>
                </div>
        </div>
        <div className="rs-contact home-style1 home-style3 bg16 pt-120 md-pt-80">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-8 pr-130 md-pr-15 md-mb-50">
                            <div className="contact-img">
                                <img src="assets/images/contact/contact.png" alt="Images"/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="widget-item mb-20">
                                <div className="widget-img">
                                    <img src="assets/images/contact/icons/2-1.png" alt="Images"/>
                                </div>
                                <div className="address-item">
                                    <div className="address-icon">
                                        <img src="assets/images/contact/icons/1.png" alt="Images"/>
                                    </div>
                                    <div className="address-text">
                                        <h3 className="title"> Address</h3>
                                        <p className="services-txt">  
                                            No 3 Thiruvalluvar Nagar , <br/>
                                            Ramanathapuram, Coimbatore
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-item mb-20">
                                <div className="widget-img">
                                    <img src="assets/images/contact/icons/2-2.png" alt="Images"/>
                                </div>
                                <div className="address-item">
                                    <div className="address-icon">
                                        <img src="assets/images/contact/icons/2.png" alt="Images"/>
                                    </div>
                                    <div className="address-text">
                                        <h3 className="title">Email us</h3>
                                        <p className="services-txt">  
                                            <a href="mailto:awards@ebehind.in">awards@ebehind.in</a> <br/>
                                            <a href="mailto:ebehindmagazine@gmail.com">ebehindmagazine@gmail.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-item">
                                <div className="widget-img">
                                    <img src="assets/images/contact/icons/2-3.png" alt="Images"/>
                                </div>
                                <div className="address-item">
                                    <div className="address-icon">
                                        <img src="assets/images/contact/icons/3.png" alt="Images"/>
                                    </div>
                                    <div className="address-text">
                                        <h3 className="title">Call us</h3>
                                        <p className="services-txt">  
                                            <a href="tel:+917418575747">+91 7418 57 57 47</a><br/>
                                            <a href="tel:9047375747">90 47 37 57 47 </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                   </div> 
        
            <footer>
            <div className="footer-bottom">
                <div className="container">                    
                    <div className="row y-middle">
                      <div className="col-lg-6">
                            <div className="copyright text-lg-start text-center ">
                                <br /><p>© 2023 E-BEHIND AWARDS | E-BEHIND DIGITAL MAGAZINE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div id="scrollUp" className="orange-color">
            <i className="fa fa-angle-up"></i>
        </div>
       
    </Fragment>
    );
}

export default Contact;