import React from "react";
import { Fragment } from "react";

const Modal=({content,state,onClose})=>{

    return(
        <Fragment>
            <style jsx="true">
            {`
                dialog {
                    border: none;
                    box-shadow: #00000029 2px 2px 5px 0px;
                    border-radius: 10px;
                    padding: 20px;
                    top: 50%;
                }
                dialog::backdrop {
                    background: #673ab752;
                }
                ::backdrop{
                    background: #673ab752;
                }
                .modal{
                    background: #673ab752;
                    display: ${state ? 'block' : 'none'};
                }
            `}
            </style>
        <div className="modal">
                <dialog open={state}>
                    {content}
                </dialog>
        </div>
        </Fragment>
    );
}

export default Modal;