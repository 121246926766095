import * as XLSX from 'xlsx';

export const countDownFunction=(countDownDate)=>{

    let now = new Date().getTime();
    
  // Find the duration between now and the count down date
  let duration = countDownDate - now;
    
  // Time calculations for days, hours, minutes and seconds
  let days = Math.floor(duration / (1000 * 60 * 60 * 24));
  let hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((duration % (1000 * 60)) / 1000);
  // omit zero values if necessary commented for now
  // let resultString =[days,hours,minutes,seconds].filter((val)=>val > 0);
 
  let resultString = [days,hours,minutes,seconds].map((val)=> val < 10 ? `0${val}` : val);
   resultString = resultString.join(" : ");
   return resultString;
}

export const exportCsv=(tableID,filename)=>{
  //query the html table
  const tableData = document.querySelector(tableID);
  // create an excel workbook
  const workBook = XLSX.utils.table_to_book(tableData);
  //save the file
  XLSX.writeFile(workBook,filename)
}