import React, { Fragment } from "react";
// import './assets/css/bootstrap.min.css';
import './assets/css/animate.css';
import './assets/css/off-canvas.css';
import './assets/css/magnific-popup.css';
import './assets/css/rsmenu-main.css';
import  './style.css';
import './responsive.css';
import NewHeader from "../components/newHeader";

const LandingPage=()=>{

   return(
    <Fragment>
            <NewHeader />
        <div className="rs-timecoun-ceremony bg2 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="sec-title text-center mb-20">
                        <span className="sub-text">Hurry Up</span>
                        <h2 className="title white-color pb-27">
                           
                            Registration Closed  </h2>
                        <div className="heading-border-line"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-counter5">
                                <div className="timecounter-inner">
                                    <div className="coming-soon-part">
                                        <div className="sec-title sub-text">    
                                       
                                        </div>                                                        
                                    </div>
                                </div>
                            </div>     
                        </div>
                        <div className="col-lg-12 mt-15">
                            <div className="btn-part text-center" >
                                <a className="readon btn-text btn-disabled"  disabled={true} href="#">
                                    <span>Closed </span>
                                    <img src="assets/images/button.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rs-about style1 pt-120 pb-120 md-pt-80 md-pb-75" id="about-event">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 pr-15 md-pr-15 md-mb-50">
                            <div className="images-part">
                                <img src="assets/images/about/banner.jpg" alt="Images" />
                            </div>
                        </div>
                        <div className="col-lg-6 pl-45 md-pl-15">
                            <div className="sec-title">
                                <span className="sub-text">About Event</span>
                                <h2 className="title pb-22">E-Behind Achievers Award </h2>
                                <div className="heading-border-line left-style"></div>
                                <p className="desc margin-0 pt-40 pb-28">The E-Behind Achievers Award Edition 2 is dedicated to acknowledging outstanding individuals and organizations who have made exceptional contributions to their Field. The Award is recognition of your remarkable achievements and commitment to excellence in your field. The ceremony will be a grand affair, complete with an captivating speakers, and a wonderful opportunity to connect with fellow honorees, industry leaders, supporters etc., This event promises to be a remarkable occasion that brings together the best and the brightest in our field.</p>
                                <div className="rs-addon-services">
                                    <div className="row">
                                       <div className="col-lg-6 md-mb-30">
                                           <div className="addon-item">
                                               <div className="addon-wrap">
                                                   <div className="addon-icon">
                                                       <img src="assets/images/about/style1/icons/1.png" alt="Icons" />
                                                   </div>
                                                   <div className="addon-content">
                                                       <h5 className="title"> Where</h5>
                                                   </div>
                                               </div>
                                               <p>Joy Auditorium<br/> 
                                               (Opposite) to KMM Matriculation School, Mahatma Gandhi Rd, Irugur, Coimbatore</p>
                                         </div>
                                       </div>
                                       <div className="col-lg-6">
                                           <div className="addon-item">
                                               <div className="addon-wrap">
                                                   <div className="addon-icon">
                                                       <img src="assets/images/about/style1/icons/2.png" alt="Icons" />
                                                   </div>
                                                   <div className="addon-content">
                                                       <h5 className="title">When</h5>
                                                   </div>
                                               </div>
                                               <p>9th December 2023.<br /> 
                                               10.30am Onwards</p>
                                           </div>
                                       </div> 
                                    </div>
                                </div>
                                <div className="btn-part mt-35">
                                    <a className="readon btn-text ticket" href="/#">
                                        <span>Register Now</span>
                                    </a>
                                </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div className="rs-animation">
                    <div className="animate dot stuff2">
                        <img data-depth="0.2" src="assets/images/about/style1/1.png" alt="Icons" />
                    </div>
                    <div className="animate circle stuff3">
                        <img data-depth="0.3" src="assets/images/about/style1/2.png" alt="Icons" />
                    </div>
                    <div className="animate microphone stuff4">
                        <img data-depth="0.4" src="assets/images/about/style1/3.png" alt="Icons" />
                    </div>
                </div>
            </div>

            <div id="rs-our-sponsors" className="rs-our-sponsor sponsor-home-style5 gray-bg5 pt-110 pb-120 md-pt-70 md-pb-80">
            <div className="container">
                    <div className="all-grid-sponsors">
                        <div className="sec-title text-center mb-20">
                            <h2 className="title title3" id="our-sponsors">
                                Our Sponsors
                            </h2>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="">
                                    <img src="assets/images/sponsors/12.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="">
                                    <img src="assets/images/sponsors/11.png" alt="Icons" />
                                </a>
                            </div>
                        </div>  
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="">
                                    <img src="assets/images/sponsors/10.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a  href="https://testyourmetrics.com/">
                                    <img src="assets/images/sponsors/1.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a  href="https://yottawebservices.in/">
                                    <img src="assets/images/sponsors/3.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="https://www.saiconsultancyindia.com/">
                                    <img src="assets/images/sponsors/14.png" alt="Icons" />
                                </a>
                            </div>
                        </div> 
                        <div className="grid-item">
                            <div className="logo-img">
                                <a  href="https://antsports.in/"> 
                                    <img src="assets/images/sponsors/6.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                         <div className="grid-item">
                            <div className="logo-img">
                                <a  href="https://www.vsglobalsolutions.com/">
                                    <img src="assets/images/sponsors/5.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a  href="/">
                                    <img src="assets/images/sponsors/2.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                 <a  href="https://aadhinids.com/">
                                    <img src="assets/images/sponsors/4.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        
                       
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="https://www.saiconsultancyindia.com/">
                                    <img src="assets/images/sponsors/15.png" alt="Icons" />
                                </a>
                            </div>
                        </div> 
                        
                        <div className="grid-item">
                            <div className="logo-img">
                                <a  href="https://www.akonline.app/">
                                    <img src="assets/images/sponsors/7.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="">
                                    <img src="assets/images/sponsors/13.png" alt="Icons" />
                                </a>
                            </div>
                        </div> 
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="">
                                    <img src="assets/images/sponsors/16.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="">
                                    <img src="assets/images/sponsors/8.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="logo-img">
                                <a href="">
                                    <img src="assets/images/sponsors/9.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                                              </div>
                    
                    
                    <div className="btn-part text-center mt-40">
                    <a href="assets/Sponsors_catalog.pdf" target="_blank" className="readon btn-text ticket event">
                            <span>Become a Sponsor</span>  </a>
                    </div>
                </div>
            </div>

            <div className="rs-pricing main-home pricing-plan-style3 pt-150 blue-bg2 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-4 pl-32 md-pl-15">
                            <div className="pricing-img">
                                <img src="assets/images/categories.png" alt="Images" />
                            </div>
                        </div>
                        <div className="col-lg-8 pl-70 md-pl-15 price-responsive">
                            <div className="sec-title mb-60">
                                <h2 className="title title2 title3 title4" id="awards">
                                    Award Categories</h2>
                            </div>
                            <ul className="price-list-item">
                                <li className="price-list mb-30">
                                    <div className="price-grid-item">            
                                        <div className="top-shape"></div>
                                            <div className="price-list-text">
                                                <div className="price-list-header">
                                                    <span className="price-list-title"> TPO Excellence </span>
                                                </div>
                                                 <div className="price-list-header">
                                                    <span className="price-list-title">  Human Resource Excellence  </span>
                                                </div>
                                                
                                            
                                        <div className="bottom-shape"></div>
                                        </div>
                                    </div>
                                </li>
                                <li className="price-list mb-30">
                                    <div className="price-grid-item">            
                                        <div className="top-shape"></div>
                                            <div className="price-list-text">
                                                <div className="price-list-header">
                                                    <span className="price-list-title">Coimbatore STARTUP ENTREPRENEUR </span>
                                                     <span className="price-list-title">Coimbatore YOUNG ACHIEVER </span>
                                                     <span className="price-list-title">Coimbatore RENOWNED  SOCIAL ACTIVIST</span>
                                                </div>
                                                
                                           
                                            
                                        <div className="bottom-shape"></div>
                                        </div>
                                    </div>
                                </li>
                                
                                 <li className="price-list mb-30">
                                    <div className="price-grid-item">            
                                        <div className="top-shape"></div>
                                            <div className="price-list-text">
                                                <div className="price-list-header">
                                                    <span className="price-list-title">Coimbatore BEST ART FILM </span>
                                                     <span className="price-list-title">Coimbatore BEST MOVIE DIRECTOR</span>
                                                     <span className="price-list-title">Coimbatore RENOWNED  YOUTUBE SERIES </span>
                                                </div>
                                                                                          
                                            
                                        <div className="bottom-shape"></div>
                                        </div>
                                    </div>
                                </li>
                               <li className="price-list mb-30">
                                    <div className="price-grid-item">            
                                        <div className="top-shape"></div>
                                            <div className="price-list-text">
                                                <div className="price-list-header">
                                                    <span className="price-list-title">Coimbatore RENOWNED VIVASAYI</span>
                                                     <span className="price-list-title">Coimbatore BEST CINEMATOGRAPHER </span>
                                                </div>
                                                                                          
                                            
                                        <div className="bottom-shape"></div>
                                        </div>
                                    </div>
                                </li>
                               <li className="price-list mb-30">
                                    <div className="price-grid-item">            
                                        <div className="top-shape"></div>
                                            <div className="price-list-text">
                                                <div className="price-list-header">
                                                    <span className="price-list-title">Coimbatore YOUTUBE CONTENT CREATOR</span>
                                                     <span className="price-list-title">Coimbatore INSTAGRAM INFLUENCER  </span>
                                                </div>
                                                                                          
                                            
                                        <div className="bottom-shape"></div>
                                        </div>
                                    </div>
                                </li>
                              
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rs-team style1 bg5 pt-110 pb-120 md-pt-70 md-pb-80">
                <div className="container">
                    <div className="sec-title text-center mb-60">
                       
                        <h2 className="title pb-35">
                            E-Behind Awards Ceremony  <br/>
                            Edition 1 Showcase 
                        </h2>
                        <div className="heading-border-line"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/1.jpg" alt="Team" /></a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/2.jpg" alt="Team"/></a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/3.jpg" alt="Team" /></a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-35">
                            <div className="team-item">
                                {/* sfv */}
                                <div className="team-inner-wrap">
                                  <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/4.jpg" alt="Team" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 md-mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/5.jpg" alt="Team" /></a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 md-mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/6.jpg" alt="Team" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 sm-mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/7.jpg" alt="Team" /></a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-6">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                  <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/8.jpg" alt="Team" /></a>
                                       
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div className="col-lg-3 col-md-6 md-mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/9.jpg" alt="Team" /></a>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 md-mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/10.jpg" alt="Team" /></a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 sm-mb-35">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/11.jpg" alt="Team" /></a>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-6">
                            <div className="team-item">
                                <div className="team-inner-wrap">
                                    <div className="images-wrap">
                                        <a href="/"><img src="assets/images/team/12.jpg" alt="Team" /></a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
            <div className="footer-bottom">
                <div className="container">                    
                    <div className="row y-middle">
                      <div className="col-lg-6">
                            <div className="copyright text-lg-start text-center ">
                                <br /><p>© 2023 E-BEHIND  AWARDS  | E-BEHIND DIGITAL MAGAZINE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div id="scrollUp" className="orange-color">
            <i className="fa fa-angle-up"></i>
        </div>
       
    </Fragment>
   )

}

export default LandingPage;