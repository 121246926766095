
const fetchAPI=async({url,...config})=>{

    return await fetch(url,{...config}).then((res)=>{
                return res.json()
            }).catch((err)=>{
                return err.response;
            })
}

const fetchImage = async ({url, fallback=null, ...rest}) => {
    let imageResponse = fallback;
    return await new Promise(resolve => {
      
      fetch({url, ...rest})
        .then(dbRes => {
          imageResponse = Buffer.from(dbRes, "binary").toString("base64");
          if(url.includes(".pdf")) {
            imageResponse = "data:application/pdf;base64," + imageResponse;
          }else if(url.includes(".docx")){
            imageResponse = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + imageResponse;
            }else if(url.includes(".doc")){
                imageResponse = "data:application/msword;base64," + imageResponse;
            } else {
                    imageResponse = `data:image/png;base64,${imageResponse}`;
            }
          resolve(imageResponse);
        })
        .catch(err => {
          console.log(err);
          resolve(imageResponse);
        });
    });
};
export {
    fetchAPI,fetchImage
}