export const DOMAIN  = window.location.hostname.split('https://')[0];

export const getServerEndpoint=()=>{
    return DOMAIN === 'localhost' ? 'http://localhost:5010' : `https://${DOMAIN}/api`
}

export const serverEndPoint = getServerEndpoint();

export const basePath = {
    events: "/events",
};

export const getURL = (basePath, url, optionalServerEndpoint) => {
    return optionalServerEndpoint ? optionalServerEndpoint + basePath + url : serverEndPoint + basePath + url;
};

