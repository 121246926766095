import React, {Fragment} from 'react';
import NewHeader from '../components/newHeader';

function Awards(props) {
    return (
        <Fragment>
        <NewHeader />
        <div className="rs-breadcrumbs img4">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                        </h1>                       
                    </div>
                </div>
        </div>
        <div className="rs-pricing main-home pricing-plan-style2 gray-bg3 pt-120 md-pt-80">
              <div className="container">
                    <div className="sec-title text-center mb-70">
                        
                  <h2 className="title">
                    Award Categories<span></span>
                      </h2>
                    </div>
                    <ul className="price-list-item">
                        <li className="price-list">
                            <div className="price-grid-item">            
                                <div className="top-shape"></div>
                              <div className="price-list-text">
                                <div className="price-list-header">
                                            <span className="price-list-title">TPO Excellence<br/>
Human Resource Excellence</span>
                                </div>
                                       
                                    </div>
                                    
                                    <div className="btn-part">
                                      <a className="readon btn-text buy-now" href="http://ebehindawards.online/register">
                                            <span>Register Now</span>
                                      </a>
                              </div>
                                <div className="bottom-shape"></div>
                            </div>
                        </li>
                        <li className="price-list">
                            <div className="price-grid-item">            
                                <div className="top-shape"></div>
                              <div className="price-list-text">
                                <div className="price-list-header">
                                            <span className="price-list-title">Coimbatore STARTUP ENTREPRENEUR <br/>
Coimbatore YOUNG ACHIEVER <br/>
Coimbatore RENOWNED SOCIAL ACTIVIST</span>
                                </div>
                                       
                                    </div>
                                    
                                    <div className="btn-part">
                                      <a className="readon btn-text buy-now" href="http://ebehindawards.online/#">
                                            <span>Register Now</span>
                                      </a>
                              </div>
                                <div className="bottom-shape"></div>
                            </div>
                        </li>
                      <li className="price-list">
                            <div className="price-grid-item">            
                                <div className="top-shape"></div>
                              <div className="price-list-text">
                                <div className="price-list-header">
                                            <span className="price-list-title">Coimbatore BEST ART FILM <br/>
Coimbatore BEST MOVIE DIRECTOR <br/>
Coimbatore BEST CINEMATOGRAPHER</span>
                                </div>
                                       
                                    </div>
                                   
                                    
                                    <div className="btn-part">
                                      <a className="readon btn-text buy-now" href="http://ebehindawards.online/register">
                                            <span>Register Now</span>
                                      </a>
                              </div>
                                <div className="bottom-shape"></div>
                            </div>
                      </li>
                         <li className="price-list">
                            <div className="price-grid-item">            
                                <div className="top-shape"></div>
                                    <div className="price-list-text">
                                        <div className="price-list-header">
                                            <span className="price-list-title">Coimbatore RENOWNED VIVASAYI<br/>
Coimbatore RENOWNED YOUTUBE SERIES <br/>
</span>
                                        </div>
                                       
                                    </div>
                                   
                                    
                                    <div className="btn-part">
                                      <a className="readon btn-text buy-now" href="http://ebehindawards.online/register">
                                            <span>Register Now</span>
                                      </a>
                                    </div>
                                <div className="bottom-shape"></div>
                            </div>
                        </li>
                        <li className="price-list">
                            <div className="price-grid-item">            
                                <div className="top-shape"></div>
                                    <div className="price-list-text">
                                        <div className="price-list-header">
                                            <span className="price-list-title">Coimbatore YOUTUBE CONTENT CREATOR<br/>
Coimbatore INSTAGRAM INFLUENCER<br/>
</span>
                                        </div>
                                       
                                    </div>
                                   
                                    
                                    <div className="btn-part">
                                        <a className="readon btn-text buy-now" href="http://ebehindawards.online/register">
                                            <span>Register Now</span>
                                        </a>
                                    </div>
                                <div className="bottom-shape"></div>
                            </div>
                        </li>
                    </ul>
              </div><br/>
              
            </div> 
        
            <footer>
            <div className="footer-bottom">
                <div className="container">                    
                    <div className="row y-middle">
                      <div className="col-lg-6">
                            <div className="copyright text-lg-start text-center ">
                                <br /><p>© 2023 E-BEHIND AWARDS | E-BEHIND DIGITAL MAGAZINE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div id="scrollUp" className="orange-color">
            <i className="fa fa-angle-up"></i>
        </div>
       
    </Fragment>
    );
}

export default Awards;