import './App.css';
import LandingPage from './pages/landingPage';
import { Routes,BrowserRouter,Route } from 'react-router-dom';
import Register from "./pages/register"
import UsersList from './pages/listpage';
import Event from "./pages/event"
import Sponsors from './pages/sponsors';
import Awards from "./pages/awards"
import Hands from './pages/hands';
import Contact from './pages/contact';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />}/>
          <Route path="/register" element={<Register />} />
          <Route path="/users-list" element={<UsersList />}/>
          <Route path="/event" element={<Event />}/>
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/awards" element={<Awards />}/>
          <Route path="/helping-hands" element={<Hands />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
