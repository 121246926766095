import React, { useState, useEffect } from "react";

const NewHeader = () => {
    const [toggleMobile, setToggleMobile] = useState(false);

    useEffect(() => {
        if (toggleMobile) {
            document.body.classList.add('nav-expanded');
        } else {
            document.body.classList.remove('nav-expanded');
        }
    }, [toggleMobile]);

    const AddLibrary = (urlOfTheLibrary) => {
        const script = document.createElement("script");
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    useEffect(() => {
        AddLibrary("./assets/js/main.js");
    }, []);

    return (
        <div className="full-width-header">
            <header id="rs-header" className="rs-header header-style4">

                <div className="menu-area menu-sticky">
                    <div className="container">
                        <div className="row-table">
                            <div className="col-cell header-logo">
                                <div className="logo-area">
                                    <a href="/">
                                        <img className="normal-logo" src="assets/images/logo-black.png" alt="logo" />
                                        <img className="sticky-logo" src="assets/images/logo-black.png" alt="logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-cell">
                                <div className="rs-menu-area">
                                    <div className="main-menu">
                                        <nav className="rs-menu hidden-md">
                                            <ul className="nav-menu">
                                                <li> <a href="/event"> EVENT</a></li>
                                                <li>
                                                    <a href="/awards">AWARD CATEGORIES </a></li>
                                                <li>
                                                    <a href="/helping-hands">HELPING HANDS</a></li>

                                                <li>
                                                    <a href="/contact" rel="noreferrer">CONTACT</a></li>


                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div className="col-cell">
                                <div className="expand-btn-inner">
                                    <ul>
                                        <li className="btn-quote">
                                            <a href="/#" rel="noreferrer" target="_blank" className="quote-button">Register now</a>  
                                                                                  </li>
                                    <li className="btn-quote">
                                            <a href="https://pages.razorpay.com/pl_Mv8bwWgcyYNars/view" rel="noreferrer" target="_blank" className="quote-button">Crowdfunding</a> </li>
                                             
                                        <li className="humburger" onClick={() => { setToggleMobile(true) }}>
                                            <a id="nav-expander" className="nav-expander bar" href="about:blank">
                                                <div className="bar">
                                                    <span className="dot1"></span>
                                                    <span className="dot2"></span>
                                                    <span className="dot3"></span>
                                                    <span className="dot4"></span>
                                                    <span className="dot5"></span>
                                                    <span className="dot6"></span>
                                                    <span className="dot7"></span>
                                                    <span className="dot8"></span>
                                                    <span className="dot9"></span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <nav className="right_menu_togle mobile-navbar-menu" id="mobile-navbar-menu">
                    <div className="close-btn">
                        <a id="nav-close2" className="nav-close" href="about:blank" onClick={() => setToggleMobile(false)}>
                            <div className="line">
                                <span className="line1"></span>
                                <span className="line2"></span>
                            </div>
                        </a>
                    </div>
                    <ul className="nav-menu">
                        <li><a href="/event">About Event</a>  </li>
                        <li> <a href="/awards">Award Categories</a>  </li>
                        <li><a href="/helping-hands">Helping Hands</a>  </li>
                        <li><a href="/contact" rel="noreferrer">Contact</a>  </li>
                        <li><a href="/#" rel="noreferrer" target="_blank" className="quote-button">Register Now</a>  </li>
                        <li><a href="https://pages.razorpay.com/pl_Mv8bwWgcyYNars/view" rel="noreferrer" target="_blank" className="quote-button">Crowdfunding</a> </li>
                    </ul>
                </nav>
            </header>
        </div>
    )
}

export default NewHeader;