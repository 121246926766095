import React, {Fragment} from 'react';
import NewHeader from '../components/newHeader';

function Hands(props) {
    return (
        <Fragment>

            <NewHeader />
        <div className="rs-breadcrumbs img2">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                        </h1>                       
                    </div>
                </div>
        </div>
        <div className="rs-inner-blog pt-120 md-pt-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-details">
                                    <div className="bs-img mb-35"></div>
                                    <div className="blog-full">
                                        <div className="sec-title text-center mb-65">

                                            <h2 className="title pb-35">E-Behind Helping Hands</h2>
                                            <div className="razorpay-embed-btn"
                                                data-url="https://pages.razorpay.com/pl_Mv8bwWgcyYNars/view"
                                                data-text="Pay Now" data-color="#274EAA" data-size="large">
                                                <script>
                                                   { (function () {
                                                        var d = document; var x = !d.getElementById('razorpay-embed-btn-js')
                                                        if (x) {
                                                            var s = d.createElement('script'); s.defer = !0; s.id = 'razorpay-embed-btn-js';
                                                            s.src = 'https://cdn.razorpay.com/static/embed_btn/bundle.js'; d.body.appendChild(s);
                                                        } else {
                                                            var rzp = window['__rzp__'];
                                                            rzp && rzp.init && rzp.init()
                                                        }
                                                    })()}
                                                </script>
                                            </div>

                                            <div className="heading-border-line"></div>
                                        </div>
                                        <ul className="single-post-meta">
                                            <li className="post-comment">E-behind டிஜிட்டல் இதழ் 2020 ஆம் ஆண்டில் மக்களுக்கு
                                                நல்ல மற்றும் பயனுள்ள செய்திகளை வழங்குவதை நோக்கமாகக் கொண்டு
                                                வெளியிடப்பட்டது.எங்கள் உள்ளடக்கத்தின் காரணமாக உலகெங்கிலும் 1.40 லட்சம்
                                                வாசகர்களை குறுகிய காலத்திற்குள் அடைய முடிந்தது. இதற்கு முக்கிய காரணம்
                                                வாசகர்களாகிய நீங்கள் தான் என்பதில் மாற்றுக் கருத்து இல்லை. எங்களுடன்
                                                இணைந்த,இணைய இருக்கும் வாசகர்களுக்கு கோடானகோடி நன்றியினை தெரிவித்துக்
                                                கொள்கிறோம்.இந்த இதழ் தொடங்குவதற்க்கு முக்கிய காரணம் வாழ்கையில் வெற்றி
                                                பெற்ற, பெறப்போகிற பல துறைகளில் (சினிமா,தொழில், விளையாட்டு, படிப்பு, கலை,
                                                கண்டுபிடிப்பு மற்றும் அரசியல் ) சாதனைப் படைக்கும் கலைஞர்களை உலகுக்கு
                                                தெரியப் படுத்தி அவர்களுக்கு அங்கீகாரம் கிடைக்க சிறு ஏணிப் படியாக எங்கள்
                                                இதழ் இருக்க வேண்டும் என்பதே எங்களது விருப்பம் .அதனை இன்று வரையிலும்
                                                சிறப்பாக செய்துவருகிறோம் என்பதை மகிழ்ச்சியுடன் தெரிவித்துக் கொள்கிறோம்.

                                            </li>
                                        </ul>
                                        <blockquote>
                                            <p className="margin-0">மனிதர்களான நாம் ஒருவருக் கொருவர் உதவ வேண்டிய காலக்
                                                கட்டாயத்தில் இருக்கிறோம் ! <br/>
                                                <cite> </cite>
                                            </p>
                                        </blockquote>

                                        <h3>சிறிய உதவி </h3>
                                        <p>பிற கலைஞர்களின் திறமையை வெளிப்படுத்த எங்கள் புத்தகத்தின் மூலம் உதவும் நாங்கள்
                                            தற்போது ஏழை எளிய மக்களுக்கு உதவம் விதமாக E_behind Helping Hands என்ற குழு
                                            ஒன்றை உருவாக்கி அதன் மூலம் இல்லாதவர்களுக்கு சிறிய உதவியினை செய்ய முதல் அடியை
                                            எடுத்துத் வைத்துளோம்."மனிதர்களான நாம் ஒருவருக் கொருவர் உதவ வேண்டிய காலக்
                                            கட்டாயத்தில் இருக்கிறோம்" இதனை உணர்ந்து தொடங்கப்பட்ட எங்களுக்கு, சிறு உதவி
                                            மூலம் மற்றவர்களின் வாழ்க்கை வெளிச்சத்துக்கு சிறு ஒளியாக (உங்களின்
                                            பங்களிப்பாக )எங்களுடன் கைகோர்க்க உங்களை இரு கைகளை கூப்பி வரவேற்கிறோம். உதவ
                                            விரும்பினால், எங்கள் E -Behind Helping Hands மூலம் நிதியளிப்பதற்கான வாய்ப்பை
                                            பயன் படுத்தி 10 ரூபாய் முதல் தங்களால் முடிந்த உதவியினை தருமாறு
                                            கேட்டுக்கொள்கிறோம்.</p>

                                        <div className="razorpay-embed-btn"
                                            data-url="https://pages.razorpay.com/pl_Mv8bwWgcyYNars/view"
                                            data-text="Pay Now" data-color="#274EAA" data-size="large">
                                            <script>
                                              {  (function () {
                                                    var d = document; var x = !d.getElementById('razorpay-embed-btn-js')
                                                    if (x) {
                                                        var s = d.createElement('script'); s.defer = !0; s.id = 'razorpay-embed-btn-js';
                                                        s.src = 'https://cdn.razorpay.com/static/embed_btn/bundle.js'; d.body.appendChild(s);
                                                    } else {
                                                        var rzp = window['__rzp__'];
                                                        rzp && rzp.init && rzp.init()
                                                    }
                                                })()}
                                            </script>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
        
            <footer>
            <div className="footer-bottom">
                <div className="container">                    
                    <div className="row y-middle">
                      <div className="col-lg-6">
                            <div className="copyright text-lg-start text-center ">
                                <br /><p>© 2023 E-BEHIND AWARDS | E-BEHIND DIGITAL MAGAZINE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div id="scrollUp" className="orange-color">
            <i className="fa fa-angle-up"></i>
        </div>
       
    </Fragment>
    );
}

export default Hands;