import React, { Fragment, useRef, useState } from "react";
import "./register.css";
import { basePath, getURL } from "../api/env-variables";
import { fetchAPI } from "../api/fetch";
import { /*fileValidation,*/ isValidEmail, isValidPhoneNumber } from "../utilities/fieldValidation";
import JSON from "../assets/jsondata.json";
import NewHeader from "../components/newHeader";
import Modal from "../components/modal";

const Register = () => {
    const { categories } = JSON;
    const mandatoryFields = ["name", "email", "mobile", "awardNomination", "photo", "profile","address"];
    const initFormState = {
        name: { label: "Name", value: "", },
        email: { label: "Email Address", value: "", },
        mobile: { label: "Mobile", value: "", },
        awardNomination: { label: "Award Nomination", value: "", },
        photo: { label: "Photo (JPG/PNG)(Upto 4MB)", value: null, accept: "image/*" },
        profile: { label: "Profile ( PDF only) (Upto 4MB)", value: null, accept: "application/pdf" },
        instagramId: { label: "Instagram Id", value: "" },
        youtubeId: { label: "Youtube Channel Id", value: "" },
        company: { label: "Company", value: "", },
        address: { label: "Address (Coimbatore Only)", value: "", },
    };
    const inputFileRefs = {
        photo: useRef(),
        profile: useRef()
    }

    const initialModalContent = (<Fragment>
                                    <h4 style={{ color: "#cb3f3f" }}>Welcome to E-behind Awards 2023</h4>
                                    <p>Registration Closed</p>
                                    <sup>(coimbatore region only)</sup>
                                </Fragment>)

    const [modalState, setModalState] = useState(true);
    const [modalContent, setModalContent] = useState(initialModalContent)
    const [responseMessage, setResponseMessage] = useState({ msg: <>&nbsp;</>, state: "", });

    const [formFields, setFormFields] = useState({ ...initFormState });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormFields((prev) => ({
            ...prev,
            [name]: { ...prev[name], value },
        }));
    }

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormFields((prev) => ({
            ...prev,
            [name]: { ...prev[name], value: files[0] }
        }))
    }

    const submitData = () => {
        let errorCheck = mandatoryFields.some((key) => {
            const { value } = formFields[key];
            return value === null || value === "";
        });
        let checkMobile = !isValidPhoneNumber(formFields.mobile.value);
        let checkMail = !isValidEmail(formFields.email.value);
        //let fileCheck = fileValidation(formFields.photo.value,"image",4096) && fileValidation(formFields.profile.value,'pdf',4096)

        if (errorCheck || checkMobile || checkMail /*|| !fileCheck*/) {

            let msg = "Please";
            if (errorCheck) {
                msg += " fill all mandatory fields";
            }
            else if (checkMail) {
                msg += " enter valid email "
            }
            else if (checkMobile) {
                msg += " enter valid mobile number ";
            }
            /*else if(!fileCheck){
                msg += " upload image file for photo/profile of size less than 4mb "
            }*/
            setResponseMessage({
                msg,
                state: "error"
            });
            setModalState(true);
            setModalContent(<Fragment>
                <p style={{color:"red"}}>{msg}</p>
            </Fragment>)
            return;
        }

        let serverData = new FormData();
        Object.entries(formFields).forEach(([field, fieldConfig]) => {
            if (["photo", "profile"].includes(field)) {
                serverData.append(field, fieldConfig.value, fieldConfig.value.filename)
            } else {
                serverData.append(field, fieldConfig.value);
            }
        })
        const URL = getURL(basePath["events"], "/#");

        fetchAPI({
            url: URL,
            body: serverData, method: "POST"
        }).then((res) => {
            if (res?.msg === "SUCCESS") {
                setResponseMessage({ state: "success", msg: "Registered Successfully" });
                clearForm();
                setModalContent(<Fragment>
                                    <h5 style={{color:'green'}}>Registered Successfully</h5>
                                </Fragment>)
                setModalState(true);
                setTimeout(() => {
                    setResponseMessage({ state: "", msg: <>&nbsp;</> });
                    setModalState(false);
                    setModalContent(initialModalContent);
                }, 10000)
            } else {
                let { detail = "" } = res;
                if (detail.includes("already exists")) {
                    detail = "This email or mobile number is already registered"
                } else { detail = null; }
                setResponseMessage({ state: "error", msg: detail || "Something went wrong Please try Later" })
                setModalContent(<Fragment>
                    <h6 style={{color:"red"}}>{detail}</h6>
                </Fragment>);
                setModalState(true)
            }
        }).catch(error => {
            setResponseMessage({ state: "error", msg: "Something went wrong Please try Later" });
        })
    }

    const clearForm = () => {
        setFormFields({ ...initFormState });
        inputFileRefs.photo.current.value = null;
        inputFileRefs.profile.current.value = null;
        //setResponseMessage({msg: <>&nbsp;</>,state:""})
    }

    const ModalContent = () => {

        return (
            <center>
                {modalContent}
                <button className="register-button" onClick={() => setModalState(false)}>OK</button>
            </center>
        )
    }

    return (
        <Fragment>
            <NewHeader />
            <section className={"banner"} id="home">
                <div className='banner-register-section' style={{ top: "65vh" }}>
                    <div className='banner-main-register'>
                    </div>
                </div>
            </section>
            <Modal state={modalState} content={<ModalContent />} />
       
        </Fragment>
    )
}

export default Register;