import React, {Fragment} from 'react';
import NewHeader from '../components/newHeader';

function Sponsors(props) {
    return (
        <Fragment>
        
        
        <NewHeader />
        <div className="rs-breadcrumbs img5">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                        </h1>                       
                    </div>
                </div>
        </div>
        <div className="rs-partner sponsors-style pt-110 pb-110 md-pt-70 md-pb-0">
        <div className="container">
                    
                    <div className="sec-title text-center mb-65">
                        
                        <h2 className="title pb-35">
                            Our Event Sponsors </h2>
                        <div className="heading-border-line"></div>
                        <div class="row no-gutters">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a  href="https://testyourmetrics.com/">
                                    <img className="logo-img" src="assets/images/sponsors/1.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a  href="/">
                                    <img src="assets/images/sponsors/2.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                 <a  href="/">
                                    <img src="assets/images/sponsors/4.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a  href="https://yottawebservices.in/" rel="noreferrer">
                                    <img src="assets/images/sponsors/3.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a  href="/">
                                    <img src="assets/images/sponsors/5.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a  href="/"> 
                                    <img src="assets/images/sponsors/6.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a  href="/">
                                    <img src="assets/images/sponsors/7.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a href="https://reactheme.com">
                                    <img src="assets/images/sponsors/8.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="grid-figure">
                                <a href="https://reactheme.com">
                                    <img src="assets/images/sponsors/9.png" alt="Icons" />
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="btn-part text-center mt-40">
                        <a className="readon btn-text ticket event" href="/">
                            <span>Become a Sponsor</span>
                        </a>
                    </div>
                </div>
               
            </div> 
        
            <footer>
            <div className="footer-bottom">
                <div className="container">                    
                    <div className="row y-middle">
                      <div className="col-lg-6">
                            <div className="copyright text-lg-start text-center ">
                                <br /><p>© 2023 E-BEHIND ACHIVERS AWARD  | E-Behnd Digital Magazine</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div id="scrollUp" className="orange-color">
            <i className="fa fa-angle-up"></i>
        </div>
       
    </Fragment>
    );
}

export default Sponsors;