import React, { Fragment } from 'react';
import NewHeader from '../components/newHeader';

function Event(props) {
    return (
        <Fragment>
        
        
        <NewHeader />
        <div className="rs-breadcrumbs img1">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                        </h1>                       
                    </div>
                </div>
        </div>
        <div className="rs-about style1 pt-120 pb-120 md-pt-80 md-pb-75">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-6 pr-15 md-pr-15 md-mb-50">
                            <div className="images-part">
                                <img src="assets/images/about/style1/about.png" alt="Images"/>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-45 md-pl-15">
                            <div className="sec-title">
                                <span className="sub-text">ABOUT THE EVENT</span>
                                <h2 className="title pb-22">
                                    Welcome to the E-Behind Achievers Award Edition 2 
                                </h2>
                                <div className="heading-border-line left-style"></div>
                                <p className="desc margin-0 pt-40 pb-25">
                                    The E-Behind Achievers Award Edition 2 is dedicated to acknowledging outstanding individuals and organizations who have made exceptional contributions to their Field. The Award is recognition of your remarkable achievements and commitment to excellence in your field. The ceremony will be a grand affair, complete with an captivating speakers, and a wonderful opportunity to connect with fellow honorees, industry leaders, supporters etc., This event promises to be a remarkable occasion that brings together the best and the brightest in our field.
We transform dreams into reality and celebrate the brilliance of Coimbatore. By sponsoring our Talents & Achievers Recognition event, you're not just supporting local talent; you're becoming a part of their inspiring journey to success.<br/>

                                </p>
                               
                                <div className="btn-part mt-35">
                                    <a className="readon btn-text ticket" href="/register">
                                        <span>Register Now </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="rs-faq gray-bg4 pt-120 md-pt-80">
              <div className="container">                    
                  <div className="row y-middle">
                    
                  <div className="rs-privacy-policy pt-120 md-pt-80">
                        <div className="privacy-wrap pb-130 md-pb-90">
                            <div className="sec-title mb-50">
                                <h2 className="title title2">
                                    What's In Store:
                                </h2>
                            </div>
                            <div className="privacy-content">
                                <ul className="check-list">
                                    <li>Showcase of Talents: A mesmerizing display of Coimbatore's finest talents</li>
                                    <li>Achievers Awards: Honoring the visionaries shaping Coimbatore's future</li>
                                    <li>Inspirational Narratives: Stories that ignite passion and ambition
</li>
                                    <li>Elevated Visibility: Your brand in the spotlight, reaching 2,00,000+ readers</li>
                                    <li>Exclusive Stage Time: Address the audience and share your brand story</li>
                                     <li>Digital Dominance: Featured across our e-magazine and social media platforms</li>
                                     <li>Networking Galore: Connect with influencers, entrepreneurs, and trendsetters</li>
                                     <li>Be a Trailblazer! Partnering with us isn/’t just a sponsorship; it/’s a statement. A statement of your commitment to Coimbatore’s progress and innovation. Let’s Create Waves: Ready to make history together? Join us and let your brand’s legacy echo through Coimbatore’s success stories.</li>
                                </ul>
                          </div>
                    </div>
                         
                </div>
              
            </div>

        </div> 
        </div>
            <footer>
            <div className="footer-bottom">
                <div className="container">                    
                    <div className="row y-middle">
                      <div className="col-lg-6">
                            <div className="copyright text-lg-start text-center ">
                                <br /><p>© 2023 E-BEHIND AWARDS | E-BEHIND DIGITAL MAGAZINE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div id="scrollUp" className="orange-color">
            <i className="fa fa-angle-up"></i>
        </div>
       
    </Fragment>
    );
}

export default Event;