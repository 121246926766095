import React, { Fragment, useCallback, useEffect, useState } from "react";
import { fetchAPI } from "../api/fetch";
import { basePath, getURL } from "../api/env-variables";
import NewHeader from "../components/newHeader";
import './register.css'
import { exportCsv } from "../utilities/utilityFunctions";
const UsersList = () => {

    const header = [
        { colName: "Name", key: "name" },
        { colName: "Email Address", key: "email" },
        { colName: "Mobile", key: "mobile" },
        { colName: "Award Nomination", key: "awardNomination" },
        { colName: "Photo", key: "photo",
        getValue: (value)=>{
            return <a rel="noreferrer" href={getURL(basePath["events"],`/get-document/photo/${value}`)} target="_blank">{value}</a> 
        }
        },
        { colName: "Profile", key: "profile",
        getValue: (value)=>{
            return <a rel="noreferrer" href={getURL(basePath["events"],`/get-document/profile/${value}`)} target="_blank">{value}</a> 
        } 
        },
        { colName: "Instagram Id", key: "instagramId" ,
        getValue: (value)=>{
            return <a rel="noreferrer" href={`https://www.instagram.com/${value}/`} target="_blank">{value}</a> 
        }},
        { colName: "Facebook Id", key: "facebookId" ,
        getValue: (value)=>{
            return <a rel="noreferrer" href={`https://www.facebook.com/${value}/`} target="_blank">{value}</a> 
        }},
        { colName: "Company", key: "company" },
        { colName: "Address", key: "address" },
    ];
    const [usersList, setUserList] = useState([]);
    const [searchValue,setSearchValue] = useState({status:true,text:""});
    const fetchUsers= useCallback(()=>{
        if(searchValue.status === true)
        fetchAPI({url: getURL(basePath["events"],`/users-list?email=${searchValue.text}`)})
        .then((res)=>{
            if(res?.msg==="SUCCESS"){
                setUserList(res.asset);
                setSearchValue((prev)=>({...prev,status:false}))
            }
        })
    },[searchValue])
    
    const deleteUser=(email)=>{
        fetchAPI({url:getURL(basePath['events'],`/delete-by-email/${email}`),method:"DELETE"})
            .then((res)=>{
                if(res?.msg==="SUCCESS")
                setSearchValue((prev)=>({...prev,status:true}))
            })
    }

    useEffect(()=>{
        fetchUsers();
    },[searchValue,fetchUsers]);

    return (
        <Fragment>
            <NewHeader />
            <style>
                {`table a {
                    text-decoration: underline !important;
                }
                table th,td{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 13em;
                }
                .register-button{
                    line-height: 1em;
                    height: 2em;
                }
                `}
            </style>
            <section id="users-list" style={{minHeight:"100vh",overflowY:"scroll",maxHeight:"100vh"}}>
                <div>

                <div className="register-button" 
                style={{width:"fit-content",float:"left"}} 
                onClick={()=>exportCsv("#registered-data","E-behind Awards 2023 Registration list.xlsx")}>
                    Export
                </div>
                <div style={{float:"right",display:"flex",maxHeight:"5em"}}>
                    <input type="search" value={searchValue.text} placeholder="Search by Mail" 
                    onChange={(e)=>setSearchValue((prev)=>({...prev,text:e.target.value,
                    status: e.target.value === "",
                    }))}/>
                    <button className="register-button" onClick={(e)=>setSearchValue((prev)=>({...prev,status:true}))}>search</button>
                </div>
                </div>

                <center>
                <table id="registered-data">
                    <thead>
                        <tr> <th>No.</th>
                            {header.map(({ colName }) => {
                                return (
                                    <th key={colName}>
                                        {colName}
                                    </th>
                                )
                            })}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usersList.map((row,index) => (
                                <tr key={index}>  
                                    <td>{index+1}</td>  
                                    {header.map(({ key,getValue }) => (
                                        <td key={key} title={row[key]} >
                                            {getValue ? getValue(row[key]) : (row[key] || <div>&nbsp;</div>)}
                                        </td>
                                    ))}
                                    <td>
                                        <button onClick={()=>deleteUser(row["email"])}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                </center>
            </section>
            <footer>
            <div className="footer-bottom">
                <div className="container">                    
                    <div className="row y-middle">
                      <div className="col-lg-6">
                            <div className="copyright text-lg-start text-center ">
                                <br /><p>© 2023 E-BEHIND ACHIVERS AWARD  | E-Behnd Digital Magazine</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </Fragment>
    )
}

export default UsersList;